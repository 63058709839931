import React from 'react'

import Loadable from '@loadable/component'
const ModalVideo = Loadable(() => import('react-modal-video'))

// functional component
const ProjectDetailsSidebar = () => {
    return (
            <div className="courses-details-info">
                <ul className="info">
                    <li className="price">
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-price-tag"></i> Price</span>
                            $49
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-teacher"></i> Instructor</span>
                            Sarah Taylor
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-stopwatch"></i> Duration</span>
                            7 weeks
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-distance-education"></i> Lessons</span>
                            25
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-web"></i> Enrolled</span>
                            255 students
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-open-padlock"></i> Access</span>
                            Lifetime
                        </div>
                    </li>
                </ul>
            </div>
    )
}

export default ProjectDetailsSidebar