import React from 'react'
import {Link} from 'gatsby'
import ProjectDetailsSidebar from './ProjectDetailsSidebar'
import course1 from '../../assets/images/courses/course1.jpg'

import Loadable from '@loadable/component'
const ModalVideo = Loadable(() => import('react-modal-video'))

const ProjectDetailsInfo = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            <ModalVideo 
                channel='youtube' 
                isOpen={!isOpen} 
                videoId='CwuZb_oRfdI' 
                onClose={() => setIsOpen(!isOpen)} 
            /> 
            <section className="events-details-area pb-100">
                <div className="container">

                    <div className="row">
                        <div className="events-details-header">
                            <ul>
                                <li><i className='bx bx-calendar'></i>Wed, 20 May, 2020</li>
                                <li><i className='bx bx-map'></i>Victoria Road, New York, USA</li>
                                <li><i className='bx bx-time'></i>12.00PM</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="events-details-location">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>
                            </div>

                            <div className="courses-details-info">
                                <div className="image">
                                    <img src={course1} alt="course1" />
                                    
                                    <Link 
                                        to="#" 
                                        onClick={e => {e.preventDefault(); openModal()}} 
                                        className="link-btn popup-youtube"
                                    >
                                    </Link>

                                    <div className="content">
                                        <i className="flaticon-google-play"></i>
                                        <span>Course Preview</span>
                                    </div>
                                </div>
                            </div>

                            <div className="events-details-desc">
                                <h3>About The Event</h3>
                                <p>Dinner Parties,Dance Parties or Movie-Nights-in, this floor plan offers a livable lifestyle with luxury finish details! Former Model Home this elegant Single Family Home features natural hardwood floors,Shiplap,custom sea grass wall treatments,natural window coverings with electric blinds & more.The Bathrooms have an air of resort vacation with floor to ceiling custom tiles in the most soothing of shades. A chefs dream kitchen including built in fridge,an island large enough to entertain a crowd or prep for a feast! Granite counters,custom backsplash & stainless steel appliances.Ample storage & south facing windows make the kitchen a jewel of this home! The dining area & great room are both elevated & relaxed with plenty of space for all! 3bds/2.5ba it feels grand & spacious at 2,069ft of living space.A loft, upstairs laundry & Primary Suite that feels like a high end hotel room. Gated community;sparkling pool,Clubhouse and more! It's staycation! </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">

                            <ProjectDetailsSidebar/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>

    )
}

export default ProjectDetailsInfo